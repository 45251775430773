import React from "react";

const SuelosDelSur = () => {
  return (
    <>
      <section id="suelosDelSur">
        <h2>Suelos del sur</h2>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <div className="col-md-9">
              <p className="ps-md-5 text-start fs-4">
                Esta serie surge como resultado de la recolección de pequeños
                fragmentos de suelos (rocas, arcillas, cenizas volcánicas) con
                los que me encontré en mis viajes a la Patagonia entre dos mil
                veintiuno y dos mil veintitrés y que luego fueron procesados y
                transformados en mi taller por la acción de la temperatura del
                horno cerámico.
                <br /> <br />
                Pienso que es muy poético buscar y obtener color de un lugar
                determinado. Trabajar de esta manera me permite extraer la
                cromaticidad intrínseca de cada entorno particular y conseguir
                así un círculo cromático de territorios.
              </p>
            </div>
            <figure className="col-md-3 cajaFigure d-none d-md-block">
              <img
                className="imgDefor"
                src="/suelosDelSur/ceniza_volcanica_bariloche.jpg"
                alt="Muestra ceramica de suelos de la peninsula de valdes"
              />
            </figure>
            <img
              className="d-md-none"
              src="/suelosDelSur/ceniza_volcanica_bariloche.jpg"
              alt="Muestra ceramica de suelos de la peninsula de valdes"
            />
          </div>
          <div className="row">
            <img
              className="col-md-3 img-fluid"
              src="/suelosDelSur/suelos_del_sur_V.JPG"
              alt="Muestra ceramica de suelos de peninsula de valdes"
            />
            <img
              className="col-md-3 img-fluid"
              src="/suelosDelSur/suelos_del_sur_I.JPG"
              alt="Muestra ceramica de suelos de puerto madryn"
            />
            <img
              className="col-md-3 img-fluid"
              src="/suelosDelSur/suelos_del_sur_II.JPG"
              alt="Muestra ceramica de suelos de puerto madryn"
            />
            <img
              className="col-md-3 img-fluid"
              src="/suelosDelSur/suelos_del_sur_IV.JPG"
              alt="Muestra ceramica de suelos de las grutas"
            />
          </div>
          <div className="row">
            <figure
              className="figure d-none d-md-flex col-12"
              dangerouslySetInnerHTML={{
                __html: `<iframe
                  src="https://www.google.com/maps/d/embed?mid=1ULDHHjWWyE5mlwnfPRQJCM_IdE-HCrww&ehbc=2E312F"
                  width="100%"
                  height="550"
                  sandbox="allow-scripts allow-forms allow-same-origin"
                ></iframe>`,
              }}
            />
          </div>
          <div className="row">
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_VI.JPG"
              alt="Muestra ceramica de suelos del lago fagnano"
            />
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_VII.JPG"
              alt="Muestra ceramica de suelos del glaciar martial"
            />
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_VIII.JPG"
              alt="Muestra ceramica de suelos de el calafate"
            />
          </div>
          <div className="row">
            <figure
              className="figure d-md-none col-12"
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.google.com/maps/d/embed?mid=1ULDHHjWWyE5mlwnfPRQJCM_IdE-HCrww&ehbc=2E312F" width="100%" height="600"></iframe>`,
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default SuelosDelSur;
