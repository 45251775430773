import React from "react";

const RioResidencia = () => {
  return (
    <>
      <section id="suelosDelSur">
        <h2>Rio residencia</h2>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <div className="col-md-9 d-flex flex-column justify-content-center align-items-center">
              <p className="ps-md-5  text-center fs-4">
                Es mi deseo la igualdad para todas las formas de vida. <br />{" "}
                Nadie vale más que nadie en la naturaleza. <br />
                Todos somos valiosos para el planeta.
              </p>
              <p className="ps-md-5 text-center fs-4">
                ¿Por qué comer a aquellos seres que nos dan todo su amor? <br />
                ¿Por qué quitarles la vida a criaturas que son pura belleza para
                el mundo? <br /> ¿Por qué darles ternura y luego verlos morir?{" "}
              </p>
            </div>
            {/* <figure className="col-md-3 cajaFigure d-none d-md-block">
              <img
                className="imgDefor"
                src="/rioResidencia/procesos_rio_residencia (4).jpg"
                alt="Muestra ceramica de suelos de la peninsula de valdes"
              />
            </figure> */}
            <img
              className="p-md-5 col-md-3 "
              src="/rioResidencia/procesos_rio_residencia (4).jpg"
              alt="rio residencia"
              style={{ borderRadius: "100px" }}
            />
          </div>

          <div className="row m-0 p-0">
            <iframe
              className="m-0 p-0 col-md-5"
              src={`https://www.youtube.com/embed/MbC-QSw_MwI`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullscreen
              style={{ aspectRatio: "4 / 3" }}
            ></iframe>
            <iframe
              className="m-0 p-0 col-md-2"
              src={`https://www.youtube.com/embed/QB9ZNPQqkxU`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullscreen
              style={{ aspectRatio: "9 / 16" }}
            ></iframe>
            <iframe
              className=" m-0 p-0 col-md-5"
              src={`https://www.youtube.com/embed/szCei7gbZNo`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullscreen
              style={{ aspectRatio: "4 / 3" }}
            ></iframe>
          </div>
          <div className="row"></div>
          <div className="row">
            <div className="col-md-8 p-md-5">
              <p>
                Esta instalación de sitio específico continúa con la línea de
                trabajo que impulsa a mi obra y que está relacionada con las
                decisiones sobre aquello que consumimos, las problemáticas
                ambientales, la elección de materiales no contaminantes y el
                cuidado de otras especies. Caminar por las ruinas de un ex
                frigorífico trajo a mí la idea de muerte ligada al comercio,
                paredes que fueron construidas para que allí mueran animales.
                Observé que algunas eran rojas y noté que el color se desprendía
                manchando mis manos. Sentí curiosidad por la procedencia de ese
                color y recordé que muchas veces la sangre era usada como
                pigmento. Entonces apareció este pequeño cuarto también con
                paredes rojas y piso de vegetación, silencioso, y me dí cuenta
                que había flores silvestres y las corté como quien lleva un
                ramito a un cementerio. Andando por la ciudad me encontré con un
                ternerito en una esquina, enseguida quise fotografiarlo,
                retratarlo. Pasaba por ahí la dueña y muy tiernamente me habló
                de él, me contó que lo cuidaba desde pequeño. Y así comencé a
                preguntarme nuevamente cómo se puede comer, mandar a morir,
                aquello que tanto se cuidó. Agregué sobre la proyección el
                gancho típico de carnicería, como contraste a la ternura de la
                imagen, como un símbolo del destino que seguramente tendrá ese
                animal. El título de la obra invita a cuestionarnos y
                reflexionar sobre nuestras decisiones, acciones y
                contradicciones. El mismo fue pintado sobre la pared con óxido
                de hierro que tomé de la orilla del río.
              </p>
            </div>
            <div className="col-md-4 p-md-5 rioImagenes">
              <img
                className="col-md-12 img-fluid"
                src="/rioResidencia/procesos_rio_residencia (5).jpg"
                alt="Rio residencia"
              />
              <img
                className="col-md-12 img-fluid"
                src="/rioResidencia/procesos_rio_residencia (1).jpg"
                alt="Rio residencia"
              />
              <img
                className="col-md-12 img-fluid"
                src="/rioResidencia/procesos_rio_residencia (2).jpg"
                alt="Rio residencia"
              />
            </div>
          </div>
          <div className="row">
            <img
              className=" img-fluid"
              src="/rioResidencia/procesos_rio_residencia (3).jpg"
              alt="Rio residencia"
            />
          </div>
          <div className="row">
            <img
              className="col-md-6 img-fluid"
              src="/rioResidencia/procesos_rio_residencia (6).jpg"
              alt="Rio residencia"
            />
            <img
              className="col-md-6 img-fluid"
              src="/rioResidencia/procesos_rio_residencia (7).jpg"
              alt="Rio residencia"
            />
          </div>
          <div className="row">
            <img
              className="col-md-4 img-fluid"
              src="/rioResidencia/obra_rio_residencia (1).jpg"
              alt="Rio residencia"
            />
            <img
              className="col-md-4 img-fluid"
              src="/rioResidencia/obra_rio_residencia (4).jpg"
              alt="Rio residencia"
            />
            <img
              className="col-md-4 img-fluid"
              src="/rioResidencia/obra_rio_residencia (5).jpg"
              alt="Rio residencia"
            />
          </div>
          <div className="row">
            <img
              className="col-md-6 img-fluid"
              src="/rioResidencia/obra_rio_residencia (2).jpg"
              alt="Rio residencia"
            />
            <img
              className="col-md-6 img-fluid"
              src="/rioResidencia/obra_rio_residencia (3).jpg"
              alt="Rio residencia"
            />
          </div>
          <div className="row">
            <img
              className="img-fluid"
              src="/rioResidencia/obra_rio_residencia (6).jpg"
              alt="Rio residencia"
            />
          </div>
          <p className="p-md-5 text-center ">
            {" "}
            *Registro de fotografía y video: Lucía Aquino, Saga Barros, Maria
            Bressanello y Silvana Montecchiessi. <br />
            *Site specific para Río Residencial La Paz (Entre Rios, Argentina,
            <span className="numero 1"> 2024</span>) donde recibí la Beca
            Artista Regional.
          </p>
        </div>
      </section>
    </>
  );
};

export default RioResidencia;
